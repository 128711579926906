<template>
    <Head>
        <Meta name="robots" content="noindex, nofollow" />
        <Link rel="icon" type="image/x-icon" href="https://frontend.coassemble.com/favicon.ico" />
        <Link rel="icon" type="image/png" sizes="32x32" href="https://frontend.coassemble.com/favicon-32x32.png" />
        <Link rel="icon" type="image/png" sizes="16x16" href="https://frontend.coassemble.com/favicon-16x16.png" />
    </Head>
    <slot />
</template>

<script>
export default {
    name: 'DefaultLayout',
    inject: ['$global'],
    mounted() {
        this.$global.get().then(() => {
            if (this.$global.isLoggedIn && this.$route.path === '/signin') {
                this.$router.push('/dashboard');
            }
        });
    }
};
</script>
